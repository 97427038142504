<template>
  <div class="wh-container">
    <header class="header no-print">
      <p class="title">Faturamento SUS</p>
    </header>

    <Tab
      ref="sus_tabs"
      :index="currentTabIndex"
      :keys="tabs"
      @onChange="onChangeTab"
    >
      <router-view />
    </Tab>

    <PrintBatchModal ref="printBatchModal" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { userHasPermission } from '@/utils/localStorageManager'

export default {
  metaInfo: {
    title: 'Eyecare - Faturamento SUS'
  },
  components: {
    Tab: () => import('@/components/Tab'),
    PrintBatchModal: () => import('@/components/Tiss/Modal/PrintBatchModal')
  },
  mounted() {
    Object.keys(this.tabKeys).map(key => {
      this.tabs.push({
        label: this.tabKeys[key],
        route: this.getPathByName(this.tabKeys[key]),
        disabled: !userHasPermission(key),
        hidden: !userHasPermission(key)
      })
    })
    this.handleTabIndex()
    this.getSusGuideRequiredFields()
  },
  model: {
    // prop: 'currentIndex',
    event: 'onChange'
  },
  data() {
    return {
      currentIndex: 0,
      tabs: [],
      tabKeys: {
        FatSusDash: 'Dashboard',
        FatSusFat: 'Faturas',
        FatSusLotAEnv: 'Lotes a enviar',
        FatSusLotEnv: 'Lotes enviados',
        FatSusLotFin: 'Lotes finalizados',
        FatSusRelBPA: 'Relatório BPA',
        FatSusRelAPAC: 'Relatório APAC',
        FatSusRelAIH: 'Relatório AIH',
        FatSusConf: 'Configurações'
      }
    }
  },
  computed: {
    routesAllowed() {
      return this.tabs.filter(tab => !tab.disabled).map(tab => tab.route)
    },
    routeDefault() {
      return this.routesAllowed[0]
    },
    routePath() {
      return this.$route.path.replace(/\/faturamento-sus\//g, "")
    },
    currentTabIndex() {
      return this.tabs.findIndex(tab => tab.route === this.routePath)
    }
  },
  methods: {
    userHasPermission,
    ...mapActions('manageGuidesSus', ['getSusGuideRequiredFields']),
    onChangeTab(index, dontPush) {  
      this.currentTabIndex = index
      const path = `/faturamento-sus/${this.getPathByIndex(index)}`
      !dontPush && path !== this.$route.path && this.$router.push(path)

    },
    handleTabIndex() {
      const tabs = {
        dashboard: 0,
        faturas: 1,
        'lotes-a-enviar': 2,
        'lotes-enviados': 3,
        'lotes-finalizados': 4,
        'relatorio-de-guias-bpa': 5,
        'relatorio-de-guias-apac': 6,
        'relatorio-de-guias-aih': 7,
        configuracoes: 8,
        default: 0
      }
      const tab = this.$route.path?.split('/')[2]
      
      return this.routesAllowed.includes(tab)
        ? this.onChangeTab(tabs[tab], false)
        : this.onChangeTab(tabs[this.routeDefault], false)
    },
    getPathByIndex(index) {
      const indexes = {
        0: 'dashboard',
        1: 'faturas',
        2: 'lotes-a-enviar',
        3: 'lotes-enviados',
        4: 'lotes-finalizados',
        5: 'relatorio-de-guias-bpa',
        6: 'relatorio-de-guias-apac',
        7: 'relatorio-de-guias-aih',
        8: 'configuracoes'
      }
      return indexes[index] || 'dashboard'
    },
    getPathByName(name) {
      const routes = {
        Dashboard: 'dashboard',
        Faturas: 'faturas',
        'Lotes a enviar': 'lotes-a-enviar',
        'Lotes enviados': 'lotes-enviados',
        'Lotes finalizados': 'lotes-finalizados',
        'Relatório BPA': 'relatorio-de-guias-bpa',
        'Relatório APAC': 'relatorio-de-guias-apac',
        'Relatório AIH': 'relatorio-de-guias-aih',
        Configurações: 'configuracoes'
      }
      return routes[name] || 'dashboard'
    },
    // verificar se pode excluir esse método
    changeTabEmmit(data) {
      const { tab } = data
      this.$router.push({ params: { tab } })
    },
    isTab(current) {
      return this.tabs[this.currentTabIndex]?.label === current
    },
    print() {
      window.print()
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .wh-button {
    margin: 0 5px;
    border-color: var(--blue-500);
    color: var(--blue-500);
  }
  .whw-button {
    margin: 0 5px;
    color: white;
  }
}
</style>
